
import { grey } from "@mui/material/colors";
import React from "react"




const FooterB =()=> {
    return (

    <footer class="text-center text-white" >
  
  <div class="text-center p-3" style={{backgroundColor: grey[500]}}>
    © 2024 Copyright:
    <a class="text-white" href="https://invitatii-web.md/"> www.invitatii-web.md</a>
  </div>
    </footer>  

    )
}

export default FooterB;