import restaurant from "./images/astoria.jpg";
import img_card from "./images/1a.jpg";
import imgheaderrestaurant from "./images/welcom.jpg";
import imgheaderrestaurantmb from "./images/welcom2.jpg";

import imgheader from "./images/header.webp";

import imgheaderm from "./images/headermb.webp";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/ring.webp";
import imgheadermiinimb from "./images/ring.webp";



const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Vadim",
        mireasa: "Cristina",
        data: "1 Septembrie 2024",
        data_confirmare: "10 august 2024",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "ursachicristina1@gmail.com", 
       tel: "+373 699 98 055",
       phone: "tel:+37369998055",
       viber: "viber://chat?number=%2B37369998055",
       whatsapp: "https://wa.me/+37369998055",
       messenger: "https://www.messenger.com/t/100009972692988",
       tel1: "+373xxx",
       phone1: "tel:+373xxx",
       viber1: "viber://chat?number=%2B373xxx",
       whatsapp1: "https://wa.me/+373xxx",
       messenger1: "https://www.messenger.com/t/xx",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Astoria Banquet Hall",
            data: "1 septembrie 2024, duminică, ora 18:00",
            adress: "str. Nicolai Iorga 26, Bălți",
            harta: "https://maps.app.goo.gl/zkoYyYKiGd6uoWN99",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10728.406693535966!2d27.9405939!3d47.7600822!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cb60d541c64aed%3A0xb02c988bccdce26c!2z0JHQsNC90LrQtdGC0L3Ri9C5INC30LDQuyDQkNGB0YLQvtGA0LjRjw!5e0!3m2!1sro!2s!4v1717232182862!5m2!1sro!2s"   
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care vom celebra căsătoria nostră, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            ps: "PS. Vă vom fi recunoscători dacă tradiționalul buchet de flori va fi înlocuit cu un cadou simbolic în semn de recunoștință.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua în care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            ps: 'PS. Vă vom fi recunoscători dacă tradiționalul buchet de flori va fi înlocuit cu o carte sau cu o sticlă de vin în semn de recunoștință.' 
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            img2: imgheaderrestaurantmb,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
           
            img: imgheadermiini,
          
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "CU BINECUVÂNTAREA PĂRINȚILOR",
            parintii_nume1: "Vitalii & Silvia",
            parintii_nume2: "Anatolie & Ludmila",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Andrei & Ana",
        }
    ],

}

export default data;